.loading {
    opacity: 0;
    transition: opacity .8s ease-in-out;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}

as-root:empty+.loading {
    opacity: 1;
    z-index: 100;
}

as-root:empty+.loading h1 {
    color: #cf8667;
    position: absolute;
    top: 40%;
    width: 100%;
    text-align: center;
    transform: translate(0, -50%);
}

.initial-spinner {
    width: 70px;
    text-align: center;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 0;
}

.initial-spinner>div {
    width: 18px;
    height: 18px;
    background-color: #cf8667;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.initial-spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.initial-spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0)
    }
    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}
